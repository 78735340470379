import {
  CatalogIcon,
  CheckoutIcon,
  FavoritesIcon,
  HomeIcon,
  LoginIcon,
  ProfileIcon,
} from '@/components/layout/icons/menu/menu';
import MenuLink from '@/components/ui/menu/MenuLink';

import { analyticMetric } from '@/helpers/analytics-metric';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHasMounted } from '@/hooks/useHasMounted';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import { changeLoginPage } from '@/store/slices/login-user/login-user.slice';

import { ROUTES } from '@/constants/routes.constant';

import stl from './MobileNavigation.module.scss';

function MobileNavigation() {
  const hasMounted = useHasMounted();
  const login = useTypedSelector((state) => state.user.login);
  const dispatch = useAppDispatch();
  const products = useTypedSelector((state) => state.checkoutProducts.products);
  const favorites = useTypedSelector((state) => state.favoriteProducts.products);

  const openLoginModal = () => {
    if (hasMounted && !login) {
      dispatch(changeLoginPage(1));
    }
  };

  return (
    <div className={stl.container}>
      <MenuLink text="Главная" href={ROUTES.HOME} testId="home" icon={<HomeIcon />} />
      <MenuLink
        text="Каталог"
        href={ROUTES.CATALOG('bukety')}
        testId="catalog"
        icon={<CatalogIcon />}
      />
      <MenuLink
        text="Избранное"
        href={ROUTES.FAVORITES}
        testId="favorites"
        notEmpty={Boolean(favorites.length && hasMounted)}
        icon={<FavoritesIcon />}
      />
      <MenuLink
        text="Корзина"
        href={ROUTES.CHECKOUT}
        testId="checkout"
        notEmpty={Boolean(products.length && hasMounted)}
        icon={<CheckoutIcon />}
      />
      {login && hasMounted ? (
        <MenuLink
          text="Профиль"
          href={ROUTES.CABINET.PROFILE}
          testId="cabinet-prodile"
          className={stl.profile_icon}
          icon={<ProfileIcon />}
          onClick={() => analyticMetric.hitGoal('knopka-profil')}
        />
      ) : (
        <MenuLink
          text="Вход"
          href=""
          testId="open-login-modal"
          className={stl.login_icon}
          disabled
          icon={<LoginIcon />}
          onClick={openLoginModal}
        />
      )}
    </div>
  );
}

export default MobileNavigation;
